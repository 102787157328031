import { create } from 'zustand';

export interface Source {
  favicon?: string;
  title?: string;
  snippet?: string;
  link: string;
  faviconFailed?: boolean;
}

export interface SourcesState {
  sources: Source[];
  setSources: (sources: Source[]) => void;
  clearSources: () => void;
}

export const useSourcesStore = create<SourcesState>((set) => ({
  sources: [],
  setSources: (sources) => set({ sources }),
  clearSources: () => set({ sources: [] })
}));
