export const chatPopupStyle = () => {
	return `
.image-container-exe {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1px;
}

.image-container-exe.odd-count-exo {
	grid-template-columns: repeat(2, 1fr);
	grid-auto-flow: dense;
}

.image-container-exe.odd-count-exo .image-est90:last-child {
	grid-column: 1 / -1;
}

.image-container-exe .image-exe-tro {
	padding: 5px;
	box-sizing: border-box;
}

.image-exe-tro {
	box-sizing: border-box;
	border-radius: 15px;
	width: 100%;
}

.image-container-exe .image-est90.square-exo {
	object-fit: cover;
	width: 100%;
	padding-top: 80%;
	position: relative;
	max-height: 300px;
}

.image-container-exe .image-est90.square-exo img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-container-exe .image-est90.full-width-exo {
	width: 100%;
	padding-top: 50%;
	position: relative;
	max-height: 400px;
}

.image-container-exe .image-est90.full-width-exo img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-height: inherit;
}

@media screen and (max-width: 768px) {
	.image-container-exe .image-est90.square-exo {
		padding-top: 100%;
		max-height: 200px;
	}

	.image-container-exe .image-est90.full-width-exo {
		padding-top: 60%;
		max-height: 300px;
	}
}
	`;
};
