import React, { useEffect, useState } from "react";
import { scrollPage } from "../utils/scroll";
import { Response } from "../components/chatPopUp/StyledComponent";

const TypewriterEffect = ({
	text,
	speed,
	streaming,
	renderText,
}: {
	text: string;
	speed: number;
	streaming: boolean;
	renderText?: (text: string) => React.ReactNode;
}) => {
	const [displayText, setDisplayText] = useState("");
	const [index, setIndex] = useState(0);

	const formatHeaders = (input: string) => {
		return input.replace(
			/###\s(.+?)(?=:|\n|\d\.|\s*-|\s*\n\n|$|>\s|`)/g,
			"<strong style='color: #222; font-size: 17px; width: max-content'>$1</strong>"
		);
	};

	useEffect(() => {
		if (index < text.length) {
			const timeout = setTimeout(() => {
				const newText = text.slice(0, index + 1);
				setDisplayText(newText);
				setIndex((prevIndex) => prevIndex + 1);
			}, speed);

			scrollPage(true);
			return () => clearTimeout(timeout);
		}
	}, [index, text, speed]);

	const formattedText = formatHeaders(displayText);

	if (renderText) {
		const citationPattern = /\[{1,2}(\d+)\]{1,2}/g;
		const parts = formattedText.split(citationPattern);

		const contentArray = parts.map((part, idx) => {
			if (idx % 2 === 0) {
				return <span key={`part-${idx}`} dangerouslySetInnerHTML={{ __html: part }} />;
			} else {
				const citationText = `[[${part}]]`;
				return (
					<React.Fragment key={`citation-${idx}`}>
						{renderText(citationText)}
					</React.Fragment>
				);
			}
		});

		return <Response>{contentArray}</Response>;
	} else {
		return <Response dangerouslySetInnerHTML={{ __html: formattedText }} />;
	}
};

export default TypewriterEffect;
