import React, { useState, useEffect, useCallback } from "react";
import styles from "./GeneralModal.module.scss";
import { generalModalStyleInject } from "../../../injectedStyles/generalModalStyle";
import { useCitationStore } from "../../../store/citationStore";
import { CitationState } from "../../../store/citationStore";
import styled from "styled-components";

interface PROPS {
	isOpen: boolean;
	title: string;
	setTriggerClose?: React.Dispatch<React.SetStateAction<boolean>>;
	triggerClose?: boolean;
	onClose: () => void;
	content: React.ReactNode;
}

const GeneralModal: React.FC<PROPS> = ({
	isOpen,
	title,
	setTriggerClose,
	triggerClose,
	onClose,
	content,
}) => {
	const [modalActive, setModalActive] = useState(isOpen);
	const { clearCitation } = useCitationStore((state: CitationState) => state);

	useEffect(() => {
		if (isOpen) {
			setModalActive(isOpen);
		}
	}, [isOpen]);

	const handleClose = useCallback(() => {
		clearCitation();
		if (setTriggerClose) {
			setModalActive(false);
			setTriggerClose(false);
			setTimeout(() => {
				onClose();
			}, 350);
		} else {
			setModalActive(false);
			setTimeout(() => {
				onClose();
			}, 350);
		}
	}, [clearCitation, onClose, setTriggerClose]);

	useEffect(() => {
		if (triggerClose) {
			handleClose();
		}
	}, [triggerClose, handleClose]);

	const modalStyle = () => {
		if (modalActive) {
			return `generalModalContent generalModalActive ${styles.generalModalContent} ${styles.generalModalActive}`;
		} else {
			return `generalModalContent  ${styles.generalModalContent}`;
		}
	};

	if (!isOpen) {
		return null;
	}

	const GeneralModalContainer = styled.main`
		position: absolute;
		inset: 0;
		background: rgba(0, 0, 0, 0.5);
		transition: all 350ms ease-in-out;
		border-radius: 15px;
	`;

	const GeneralModalContent = styled.div`
		height: 100%;
	`;

	const CloseModal = styled.div`
		cursor: pointer;
		width: 31px;
		height: 31px;
		padding: 10.7px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		color: #fff;
	`;

	const Title = styled.p`
		color: #000;
		font-family: "CircularStd", sans-serif;
	`;

	return (
		<GeneralModalContainer>
			<style>{generalModalStyleInject()}</style>

			<GeneralModalContent>
				<div className={` ${modalStyle()}`}>
					<div
						className={`modalItem modalItemActive ${
							modalActive ? styles.modalItemActive : ""
						} ${styles.modalItem}`}
					>
						<div className={`${styles.heading} generalMHeading`}>
							<Title>{title}</Title>
							<CloseModal
								role="button"
								style={{ background: `#000` }}
								onClick={handleClose}
							>
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13 1L1 13"
										stroke="#fff"
										strokeWidth="3"
										strokeLinecap="round"
									/>
									<path
										d="M1 1L13 13"
										stroke="#fff"
										strokeWidth="3"
										strokeLinecap="round"
									/>
								</svg>
							</CloseModal>
						</div>
						<div className={`${styles.mainContent} mainContentGeneral`}>{content}</div>
					</div>
				</div>
			</GeneralModalContent>
		</GeneralModalContainer>
	);
};

export default GeneralModal;
