import React, { useState } from "react";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

interface SyntaxHighlighterWrapperProps {
	language?: string;
	children?: any;
}

const SyntaxHighlighterWrapper: React.FC<SyntaxHighlighterWrapperProps> = ({
	language,
	children,
	...rest
}) => {
	const codeString = typeof children === "string" ? children : children?.toString();
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(codeString).then(() => {
			setCopied((prev) => !prev);
		});

		setTimeout(() => {
			setCopied((prev) => !prev);
		}, 2000);
	};
	return (
		<div style={{ width: "100%", marginBlock: "10px", fontSize: "13px" }}>
			<div
				style={{
					background: "#444",
					color: "#eee",
					marginBottom: "-8px",
					height: "35px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					paddingInline: ".85rem",
					borderTopLeftRadius: "5px",
					borderTopRightRadius: "5px",
				}}
			>
				{language}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: ".4rem",
						cursor: "pointer",
					}}
				>
					<p onClick={handleCopy}>{copied ? "copied!" : "copy"}</p>
				</div>
			</div>
			<SyntaxHighlighter {...rest} PreTag="div" language={language || ""} style={oneDark}>
				{codeString}
			</SyntaxHighlighter>
		</div>
	);
};

export default SyntaxHighlighterWrapper;
