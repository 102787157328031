import React, { useState, useEffect } from "react";
import styles from "./styles/Sources.module.scss";
import truncateText from "../../utils/truncate";
import { scrollPage } from "../../utils/scroll";
import { useAllSourcesModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import { useSourcesStore } from "../../store/sourcesStore";
import { SourcesState } from "../../store/sourcesStore";
import {
	Main,
	Title,
	SourcesContainer,
	SourceCard,
	CardContent,
	CardHead,
} from "./StyledComponent";

interface SourceData {
	favicon?: string;
	title?: string;
	snippet?: string;
	link: string;
	faviconFailed?: boolean;
}

const defaultSource: SourceData = {
	title: "Source",
	snippet: "Loading source details...",
	link: "#",
	faviconFailed: false,
};

const Sources = ({ data }: { data: { organic: any[] } }) => {
	const [sources, setSources] = useState<SourceData[]>([]);
	const [allRawSources, setAllRawSources] = useState<SourceData[]>([]);
	const { onOpen } = useAllSourcesModal((state: ModalType) => state);
	const { setSources: setAllSources } = useSourcesStore((state: SourcesState) => state);

	useEffect(() => {
		const defaultSources = Array(3).fill(defaultSource);
		setSources(defaultSources);

		if (data?.organic) {
			const processedAllSources = data.organic.map((item) => {
				const url = new URL(item.link);
				const faviconUrl = `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=64`;

				return {
					favicon: faviconUrl,
					title: item.title || defaultSource.title,
					snippet: item.snippet || defaultSource.snippet,
					link: item.link,
					faviconFailed: false,
				};
			});

			setAllRawSources(processedAllSources);
			const processedSources = processedAllSources.slice(0, 3);
			setSources(processedSources);
			scrollPage(true);
		}
	}, [data]);

	const allFavicons = allRawSources?.map((e) => e.favicon);

	const handleSetSources = () => {
		onOpen();
		setAllSources(allRawSources);
	};

	return (
		<Main>
			<div className={styles.container}>
				<Title>Sources</Title>
				<SourcesContainer>
					{sources.map((source, index) => (
						<SourceCard
							key={index}
							style={{ backgroundColor: "#FAFAFA", transition: "opacity 0.2s" }}
							onMouseEnter={(e: React.MouseEvent<HTMLDivElement>) =>
								(e.currentTarget.style.opacity = "0.65")
							}
							onMouseLeave={(e: React.MouseEvent<HTMLDivElement>) =>
								(e.currentTarget.style.opacity = "1")
							}
							onClick={() => window.open(source.link, "_blank")}
						>
							<CardContent>
								<CardHead>
									{!source.faviconFailed && source.favicon && (
										<img
											src={source.favicon}
											alt=""
											width={20}
											height={20}
											style={{ borderRadius: "50%" }}
											onError={() => {
												setSources((prevSources) => {
													const newSources = [...prevSources];
													newSources[index] = {
														...newSources[index],
														faviconFailed: true,
													};
													return newSources;
												});
											}}
										/>
									)}
									<p
										onClick={() => window.open(source.link, "_blank")}
										style={{ cursor: "pointer", color: "black" }}
									>
										{truncateText(source.title || "", 12)}
									</p>
								</CardHead>
								<p className={styles.snippet} style={{ color: "#6d6a6ac5" }}>
									{truncateText(source.snippet || "", 35)}
								</p>
							</CardContent>
						</SourceCard>
					))}
					{data?.organic?.length > 3 && (
						<div
							style={{
								padding: "12px",
								cursor: "pointer",
								borderRadius: "8px",
								background: "#EFEFEF",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "space-between",
								fontSize: "14px",
								color: "#333",
							}}
							onClick={handleSetSources}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "6px",
									flexWrap: "wrap",
								}}
							>
								{allFavicons &&
									allFavicons.splice(0, 5)?.map((favicon, idx) => (
										<img
											src={favicon}
											alt=""
											key={idx}
											style={{
												width: "20px",
												height: "20px",
												borderRadius: "50%",
											}}
										/>
									))}
							</div>
							<button>All sources</button>
						</div>
					)}
				</SourcesContainer>
			</div>
		</Main>
	);
};

export default Sources;
