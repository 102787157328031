import styled from "styled-components";

const Main = styled.main`
	width: 100%;
	align-self: flex-start;
	border-radius: 0px 15px 15px 15px;
	color: #1a4734;
	font-weight: 500;
	justify-content: center;
	animation: fadeIn 1s ease-in-out forwards;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const Title = styled.h1`
	color: #000;
	margin-top: 55px;
	margin-bottom: 10px;
`;

const SourcesContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 12px;

    @media screen and (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const SourceCard = styled.div`

	width: 100%;
	border: 1px solid #eaeaea;
	padding: 11px;
	border-radius: 8px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: #fafafa;
	justify-content: space-between;

	&:hover {
		opacity: 0.65;
		cursor: pointer;
	}
`;

const CardContent = styled.div`
	display: flex;
	flex-direction: column;
	color: #6d6a6a;
	gap: 4px;
`;

const CardHead = styled.div`
	color: #000;
	display: flex;
	gap: 4px;
	text-transform: capitalize;
	text-decoration: underline;
`;

export { Main, Title, SourcesContainer, SourceCard, CardContent, CardHead };
