import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatPopUp.module.scss";
import Modal from "../modal/Modal";
import { useChatInterfaceModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import useChat from "../../store/chatStore";
import { ChatState } from "../../store/chatStore";
import UseMarkdown from "../../hooks/useMarkdown";
import { scrollPage } from "../../utils/scroll";
import EntryPage from "../entryPage/EntryPage";
import PromptBox from "../promptBox/PromptBox";
import useBrand from "../../store/brandStore";
import { BrandStore } from "../../store/brandStore";
import { userTimeZone } from "../../utils/timezone";
import AllSources from "../modal/generalModal/allSources/AllSources";
import { BsCopy } from "react-icons/bs";
import { BiCheckDouble } from "react-icons/bi";
import { GoThumbsup } from "react-icons/go";
import { GoThumbsdown } from "react-icons/go";
import { getContrastColor } from "../../utils/getContrastColor";
import Citation from "../modal/generalModal/citation/Citation";
import SVGIcon from "./svgs";
import { chatPopupStyle } from "../../injectedStyles/chatPopup";

import {
	Main,
	ChatContent,
	Header,
	ChatContainer,
	LogoContainer,
	Logo,
	Snapshot,
	PoweredByContainer,
	PoweredBy,
	LinkPower,
	Close,
	ActionsP,
	CopyResponse,
	ResponseContainer,
	Response,
	PromptGroup,
	PromptWrapper,
	Prompt,
	FeedbackContainer,
	LoadingPulse,
	Feedback,
	TriggerImg,
	Chats,
	PromptBoxChat,
} from "./StyledComponent";

// Widget components
import Sources from "../widgets/Sources";

const CDN_URL = process.env.REACT_APP_CDN_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const base = process.env.REACT_APP_URL;
const ast = process.env.REACT_APP_AST;
const pxa = process.env.REACT_APP_PXA;

export interface Props {
	client_id?: string;
	user_id: string;
	quick_prompts?: string[];
	first_name?: string;
	meta_data?: {};
	session_id?: string;
}

const ChatPopUp = ({
	client_id,
	user_id,
	quick_prompts,
	first_name,
	meta_data,
	session_id,
}: Props) => {
	const icons = SVGIcon();
	const { brand } = useBrand((state: BrandStore) => state);
	const [copiedMessages, setCopiedMessages] = useState<{ [key: number]: boolean }>({});
	const [randomPrompts, setRandomPrompts] = useState<string[]>([]);
	const stopStreamFlag = useRef(false);
	const abortControllerRef = useRef<AbortController | null>(null);
	const { isOpen, onClose } = useChatInterfaceModal((state: ModalType) => state);
	const { Messages, addMessage, toolFeedback, setToolFeedback, sessionId } = useChat(
		(state: ChatState) => state
	);
	const [triggerClose, setTriggerClose] = useState(false);
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const scrollContainerRef = useRef<HTMLElement | null>(null);
	const [text, setText] = useState("");
	const [generating, setGenerating] = useState(false);
	const [streaming, setStreaming] = useState(false);
	const [loading, setLoading] = useState(false);


	console.log(user_id,'kk');
	

	useEffect(() => {
		if (quick_prompts && quick_prompts.length > 0) {
			const shuffledPrompts = [...quick_prompts].sort(() => 0.5 - Math.random());
			setRandomPrompts(shuffledPrompts.slice(0, 4));
		}
	}, [quick_prompts]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
	};

	useEffect(() => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	}, [text]);

	const handleClose = () => {
		setTriggerClose(true);
	};

	const stopStream = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
			abortControllerRef.current = null;
		}
		setGenerating(false);
		setStreaming(false);
		setLoading(false);
		setToolFeedback("");
		stopStreamFlag.current = true;
	};

	let intentId = "";
	let intentValue = "";
	let currentEvent = "";
	let currentData = "";
	let messageBuffer = "";

	const chatClive = async () => {
		setGenerating(true);
		setLoading(true);
		const basicAuth = "Basic " + btoa(ast + ":" + pxa);

		try {
			abortControllerRef.current = new AbortController();
			const response = await fetch(`${base}/chat-snapshot-stream`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: basicAuth,
				},
				body: JSON.stringify({
					sentence: text,
					partnerId: client_id?.trim() ?? "",
					...(user_id.trim().length > 0 && { userId: user_id?.trim() }),
					sessionId: sessionId,
					ragEnabled: true,
					permissions: ["super_admin"],
					metadata: meta_data ?? {},
					timeZone: userTimeZone(),
				}),
				signal: abortControllerRef.current?.signal,
			});

			if (response.ok) {
				const reader = response.body && response.body.getReader();
				const decoder = new TextDecoder("utf-8");
				let partialMessage = "";

				if (reader) {
					setStreaming(true);
					stopStreamFlag.current = false;
					while (!stopStreamFlag.current) {
						const { done, value } = await reader.read();

						if (done) {
							setStreaming(false);
							break;
						}
						partialMessage += decoder.decode(value, { stream: true });
						const messages = partialMessage.split("\n\n");

						for (const message of messages.slice(0, -1)) {
							const lines = message.split("\n");

							for (const line of lines) {
								if (line.startsWith("event:")) {
									// Handle previous event if there's data
									if (currentEvent) {
										handleEvent(currentEvent, currentData?.trim());
										currentEvent = "";
										currentData = "";
									}

									// Extract current event
									currentEvent = line?.replace("event: ", "")?.trim();
								} else if (line.startsWith("data:")) {
									// Append data for current event
									currentData += line?.replace("data:", "")?.trim();

									// Event-specific handling
									if (currentEvent === "message_start") {
										setToolFeedback("");
										setLoading(false);
										updateMessageBuffer(line?.replace("data:", "")?.trim());
									} else if (currentEvent === "tool_feedback") {
										setToolFeedback(currentData?.trim());
										// scrollPage();
									} else if (currentEvent === "end_stream") {
										setToolFeedback("");
										setLoading(false);
									} else if (currentEvent === "tool_response") {
										try {
											const widgetData = JSON.parse(currentData?.trim());
											const keys = Object.keys(widgetData);
											if (keys.length === 1) {
												const key = keys[0];
												const data = widgetData[key];
												if (
													key === "793695195" &&
													(data.organic || data.topStories)
												) {
													addMessage({
														responses: [<Sources data={data} />],
														type: "widget",
													});
												}
											}
										} catch (error) {
											console.error(
												"Error parsing tool_response data:",
												error
											);
										}
										// scrollPage(true);
									}
								}
							}
						}

						partialMessage = messages[messages.length - 1];
					}
				}
				if (currentEvent) {
					handleEvent(currentEvent, currentData?.trim());
				}
			} else {
				throw new Error("Failed to send message");
			}
		} catch (error) {
			if (error instanceof Error && error.name !== "AbortError") {
				setToolFeedback("");
			}
		} finally {
			setGenerating(false);
			setStreaming(false);
			setToolFeedback("");
		}
	};

	// const handleEvent = (eventType: string, data: string) => {
	// 	// const formatted = data?.replace(/\\n/g, "<br/>");
	// 	if (eventType === "start_stream") {
	// 		handleMessageStart(data);
	// 	} else if (eventType === "message_end") {
	// 		handleMessageEnd();
	// 	} else if (eventType === "citations") {
	// 		console.log("another one", data);
	// 	}
	// };

	const handleEvent = (eventType: string, data: string) => {
		if (eventType === "start_stream") {
			handleMessageStart(data);
		} else if (eventType === "message_end") {
			handleMessageEnd();
		} else if (eventType === "citations") {
			try {
				const parsedCitations = JSON.parse(data?.trim());
				addCitationsToLastMessage(parsedCitations); // Add citations to last message's responses
			} catch (err) {
				//
			}
		}
	};

	const addCitationsToLastMessage = (citations: any) => {
		const currentMessages = useChat.getState().Messages;
		const lastMessageIndex = currentMessages.length - 1;

		if (lastMessageIndex >= 0) {
			const lastMessage = currentMessages[lastMessageIndex];
			if (!lastMessage.responses) {
				lastMessage.responses = []; // Initialize responses if not defined
			}

			// Ensure citations are added to the last response in the responses array
			if (lastMessage.responses.length > 0) {
				lastMessage.responses[0].citations = citations;
			}

			useChat.setState({ Messages: [...currentMessages] });
		}
	};

	const handleMessageStart = (data: string) => {
		messageBuffer = data;
	};

	const handleMessageEnd = () => {
		messageBuffer = "";
		setGenerating(false);
		setLoading(false);
		setToolFeedback("");
	};

	const updateMessageBuffer = (data: string) => {
		scrollPage();
		messageBuffer = data;
		const formattedMessage = messageBuffer;

		const currentMessages = useChat.getState().Messages;
		const lastMessageIndex = currentMessages.length - 1;

		if (lastMessageIndex >= 0) {
			const lastMessage = currentMessages[lastMessageIndex];
			lastMessage.responses = lastMessage.responses || [];
			if (lastMessage.responses.length > 0) {
				lastMessage.responses[0].clive_response = formattedMessage?.trim();
			} else {
				lastMessage.responses.push({
					clive_response: formattedMessage?.trim(),
					tools_response: [],
				});
			}

			useChat.setState({ Messages: [...currentMessages] });
		} else {
			const updatedMessage = {
				responses: [
					{ clive_response: formattedMessage?.trim(), tools_response: [] },
					{ intent: intentValue, intentId },
				],
				type: "received",
			};
			addMessage(updatedMessage);
		}
	};

	const sendMessage = async () => {
		setText("");
		addMessage({
			prompt: text,
			type: "sent",
			fileName: "",
			fileUrl: "",
			imageUrl: "",
		});

		chatClive();
	};

	useEffect(() => {
		const scrollLastContentToTop = () => {
			const scroll = scrollContainerRef.current;

			if (scroll) {
				const lastElement = scroll.lastElementChild;
				if (lastElement) {
					const lastContentHeight = lastElement.clientHeight + 130;
					scroll.scrollTo({
						top: scroll.scrollHeight - lastContentHeight,
					});
				}
			}
		};

		scrollLastContentToTop();
	}, [Messages]);

	const handleCopyClick = async (textToCopy: string, messageIndex: number) => {
		await navigator.clipboard.writeText(textToCopy);
		setCopiedMessages((prev) => ({ ...prev, [messageIndex]: true }));
		setTimeout(() => {
			setCopiedMessages((prev) => ({ ...prev, [messageIndex]: false }));
		}, 5000);
	};

	const content = (
		<Main>
			<style>{chatPopupStyle()}</style>
			<ChatContent style={{ backgroundColor: `#${brand.brandColor}` }}>
				<Header isMessage={Messages?.length > 0}>
					{Messages?.length > 0 ? (
						<LogoContainer>
							<Logo src={brand.brandLogo ?? `${CDN_URL}/trigger.svg`} alt="logo" />
							<PoweredByContainer>
								<Snapshot>{brand.whitelist ? brand.name : "Snapshot"}</Snapshot>
								{brand.whitelist ? (
									""
								) : (
									<PoweredBy>
										Powered by{" "}
										<LinkPower
											href="https://www.cliveai.com/"
											rel="noreferrer"
											className={`linkPower ${styles.link}`}
											target="_blank"
										>
											Clive AI
										</LinkPower>
									</PoweredBy>
								)}
							</PoweredByContainer>
						</LogoContainer>
					) : (
						<p></p>
					)}
					<Close
						role="button"
						style={{ background: `#${brand.secondaryColor}` }}
						onClick={handleClose}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13 1L1 13"
								stroke={getContrastColor(brand.secondaryColor)}
								strokeWidth="3"
								strokeLinecap="round"
							/>
							<path
								d="M1 1L13 13"
								stroke={getContrastColor(brand.secondaryColor)}
								strokeWidth="3"
								strokeLinecap="round"
							/>
						</svg>
					</Close>
				</Header>

				{Messages?.length < 1 ? (
					<EntryPage
						firstName={first_name}
						randomPrompts={randomPrompts}
						setText={setText}
						textareaRef={textareaRef}
						text={text}
						generating={generating}
						sendMessage={sendMessage}
						handleChange={handleChange}
						sendDisabled={icons.sendDisabled}
						stopStream={stopStream}
						stop={icons.stop}
					/>
				) : (
					<>
						<ChatContainer id="chatContainer" ref={scrollContainerRef}>
							{Messages.map((message: any, index: number) => {
								const isLastMessage = index === Messages.length - 1;
								return (
									<Chats key={index}>
										{message.type !== "widget" ? (
											<>
												{message.prompt && (
													<PromptGroup>
														{message.prompt && (
															<PromptWrapper>
																<Prompt>{message.prompt}</Prompt>
															</PromptWrapper>
														)}
													</PromptGroup>
												)}

												{message?.responses?.length > 0 && (
													<>
														{(() => {
															try {
																const responseData =
																	message.responses[0];
																if (responseData?.clive_response) {
																	return (
																		<ResponseContainer>
																			<TriggerImg
																				src={`${CDN_URL}/logo-alt.svg`}
																				alt="trigger"
																			/>
																			<Response>
																				<UseMarkdown
																					index={index}
																					children={responseData?.clive_response?.trim()}
																					streaming={
																						streaming
																					}
																					Messages={
																						Messages
																					}
																				/>
																				<ActionsP
																					className="copy-xs"
																					streaming={
																						streaming
																					}
																					isLastMessage={
																						isLastMessage
																					}
																					style={{
																						position:
																							isLastMessage
																								? undefined
																								: "absolute",
																						display:
																							"flex",
																						marginTop:
																							"8px",
																						alignItems:
																							"center",

																						width: "max-content",
																						paddingTop:
																							"7px",
																						gap: "12px",

																						transition:
																							"opacity 0.3s ease",
																					}}
																				>
																					<div>
																						<CopyResponse
																							onClick={() =>
																								handleCopyClick(
																									responseData?.clive_response?.trim(),
																									index
																								)
																							}
																						>
																							{copiedMessages[
																								index
																							] ? (
																								<BiCheckDouble
																									style={{
																										width: "22px",
																										height: "22px",
																										color: "#BCBCBC",
																									}}
																								/>
																							) : (
																								<BsCopy
																									style={{
																										width: "16px",
																										height: "16px",
																										color: "#BCBCBC",
																									}}
																								/>
																							)}
																						</CopyResponse>
																					</div>

																					<div
																						style={{
																							display:
																								"none",
																							alignItems:
																								"center",
																							gap: "10px",
																						}}
																					>
																						<GoThumbsup
																							style={{
																								width: "18px",
																								height: "18px",
																								color: "#BCBCBC",
																							}}
																						/>
																						<GoThumbsdown
																							style={{
																								width: "18px",
																								height: "18px",
																								color: "#BCBCBC",
																							}}
																						/>
																					</div>
																				</ActionsP>
																			</Response>
																		</ResponseContainer>
																	);
																}
															} catch (error) {
																//
															}
														})()}
													</>
												)}
											</>
										) : (
											// Render widget responses
											<div>
												{message.responses.map(
													(widget: any, widgetIndex: number) => (
														<React.Fragment key={widgetIndex}>
															{widget}
														</React.Fragment>
													)
												)}
											</div>
										)}
									</Chats>
								);
							})}
							{loading && (
								<FeedbackContainer>
									<LoadingPulse src={`${CDN_URL}/trigger.svg`} alt="logoAlt" />

									<Feedback>{toolFeedback}</Feedback>
								</FeedbackContainer>
							)}
						</ChatContainer>
						<PromptBoxChat>
							<PromptBox
								text={text}
								generating={generating}
								sendMessage={sendMessage}
								stopStream={stopStream}
								handleChange={handleChange}
								textareaRef={textareaRef}
							/>

							<p
								style={{
									fontFamily: "CircularStd",
									textAlign: "center",
									paddingBottom: "10px",
									color: "#444",
									opacity: "0.5",
									fontSize: "14px",
								}}
							>
								{brand.whitelist ? brand.name : "Snapshot"} can make mistakes.
								Please double check responses
							</p>
						</PromptBoxChat>
					</>
				)}

				<AllSources />
				<Citation />
			</ChatContent>
		</Main>
	);

	return (
		<Modal
			triggerClose={triggerClose}
			setTriggerClose={setTriggerClose}
			isOpen={isOpen}
			onClose={onClose}
			content={content}
		/>
	);
};

export default ChatPopUp;
