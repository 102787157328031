import { create } from "zustand";

export interface BrandState {
  name:string;
  brandLogo: string | null;
  brandColor: string;
  secondaryColor: string;
  loading: boolean;
  quickPrompts: string[];
  welcomeMessage: string;
  greeting: string;
  whitelist:boolean
}

// Define the initial state with explicit types
const initialState: BrandState = {
  name:"",
  brandLogo: null,
  brandColor: "29645B",
  secondaryColor: "000",
  loading: false,
  quickPrompts: [],
  welcomeMessage: "",
  greeting: "",
  whitelist:false
};

export interface BrandStore {
  brand: BrandState;
  updateBrand: (updates: Partial<BrandState>) => void;
}

// Create the store
const useBrand = create<BrandStore>((set) => ({
  brand: initialState,
  updateBrand: (updates) =>
    set((state: BrandStore) => ({
      ...state,
      brand: {
        ...state.brand,
        ...updates,
      },
    })),
}));

export default useBrand;