import styled from "styled-components";

const ButtonMain = styled.div`
	padding: 0px 7px 10px;
	width: 100%;
`;

const TextArea = styled.section`
	font-size: fn.rem(10);
	display: flex;
	align-items: center;
	width: 100%;
	align-items: center;
	background: #fff;
	border-radius: 28px;
	box-shadow: 5px 5px 15px 0px rgba(198, 198, 198, .2);
	padding-inline: 8px;
	border: 1px solid #cfcfcf66;


	@media screen and (min-width: 768px) {
		padding-inline: 12px;
	}
`;

const TextAreaInput = styled.textarea<{ isScrolling: boolean }>`
	font-family: "CircularStd", sans-serif;
	white-space: pre-wrap;
	font-size: 14px !important;
	width: 100%;
	padding: 0 0.8rem;
	color: #000;
	font-weight: 400;
	resize: none;
	overflow-y: scroll;
	line-height: fn.rem(20);
	background-color: transparent;
	border-radius: 28px;
	max-height: 150px;
	min-height: 50px;
	padding: 1rem 0.7rem;
	outline: none !important;

	&::-webkit-scrollbar {
		display: inline-flex;
		width: 0.4rem;
	}

	&::-webkit-scrollbar-thumb {
		background: #7b83807b;
		border-radius: 10px;
		display: ${({ isScrolling }) => (isScrolling ? "flex" : "none")};
	}

	&::placeholder {
		font-family: "CircularStd", sans-serif;
		font-size: 14px !important;
		color: #a6a6a6;
		opacity: 0.7;
	}

	&:focus {
		outline: none !important;
	}
`;

const ActionBtns = styled.section`
	align-self: flex-end;
	margin-bottom: 10.5px;
`;

const SendBtn = styled.button<{ textLength: number }>`
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	opacity: ${({ textLength }) => (textLength < 1 ? 0.3 : 1)};
`;

const StopBtn = styled.button`
	display: flex;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
`;

export { ButtonMain, TextArea, TextAreaInput, ActionBtns, SendBtn, StopBtn };
