export const globalStyles = () => {
	return `
	@import url('https://cdn.jsdelivr.net/npm/@vetixy/circular-std@1.0.0/dist/index.min.css');

@font-face {
    font-family: 'FreightTextProMedium';
    src: url('./fonts/freighttext-pro-/FreightTextProMedium-Regular.eot');
    src: local('FreightText Pro Medium'), local('FreightTextProMedium-Regular'),
        url('./fonts/freighttext-pro-/FreightTextProMedium-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/freighttext-pro-/FreightTextProMedium-Regular.woff2') format('woff2'),
        url('./fonts/freighttext-pro-/FreightTextProMedium-Regular.woff') format('woff'),
        url('./fonts/freighttext-pro-/FreightTextProMedium-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

:root {
	line-height: 1.5;
	font-size: 16px;
	color: #686968;
	z-index:10000 !important;
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}

html {
	height: 100%;
	box-sizing: border-box;
	font-size: 100%;
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	text-decoration: none;
	list-style: none;
	box-sizing: border-box;
}


img {
	max-width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
}

a,
a:visited,
a:active {
	cursor: pointer;
	letter-spacing: .2px;
	text-decoration: none;
}

button,
input,
textarea {
	outline: none;
	border: none;
}

.BaseFont {
	font-family: "FreightTextProMedium", sans-serif;
}

// .AltFont {
// 	font-family: "Montserrat", sans-serif;;
// }

.empty {
	width: max-content;
}

.response {
	align-self: flex-start;
	border-radius: 0px 15px 15px 15px;
	color: #fff;
	font-weight: 500;
	justify-content: center;
	animation: typing 500ms ease-in-out forwards;
}

.rule {
	width: 100%;
	height: 1px;
	margin-block: 2px;
}

@keyframes typing {
	from {
		opacity: 0;
		transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.widgetsAnimate {
	animation: widget 600ms linear forwards;
}

@keyframes widget {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

body {
	margin: 0;
	padding: 0;
	height: 100%;
	z-index:10000 !important;
}

.readMore {
	font-size: 14px;
	font-weight: 500;
	text-decoration: underline;
}
.toastClass {
	font-size: 14px;
	font-weight: 600;
	border-radius: 50px;

	@media screen and (max-width: 300px) {
		font-size: 15px;
	}

	@media screen and (max-width: 500px) {
		text-align: center;
	}

	@media screen and (min-width: 768px) {
		font-size: 17.5px;
	}
}

.hr {
	background-color: transparent;
	color: transparent;
}
`;
};
