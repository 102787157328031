export const generalModalStyleInject = () => {
	return `
    .generalModal {
        position: absolute;
        inset: 0;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 15px;
	    transition: all 350ms ease-in-out;
    }

    .generalModalContainer {
        height: 100%;
	
    }

    .generalModalContent {
		display: flex;
		align-items: center;
		justify-content: center;
        transition: all 350ms ease-in-out;
        opacity: 0;
        height: 100%;
    }

	.modalItem {
	width: 75%;
		background: #FFFFFF;
		transition: transform 220ms ease-in-out, opacity 220ms ease-in-out;
		opacity: 0;
		transform: translateY(20px);
		box-shadow: 2px 2px 18px 2px #00000040;
		border-radius: 8px;
		animation: none;
		display:flex;
		flex-direction: column;
	}

	.generalMHeading{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 20px;
	border-bottom: 1px solid #F2F2F2
	}

	.titleHgenral{
	color: #000
	}

	.closeHBtn{
	background:#eee;
	padding:4px 8px;
	font-size:15px;
	cursor:pointer;
	border-radius:2px;
	}

	.mainContentGeneral{
	padding: 0 20px;
	}

	.modalItemActive {
		transform: translateY(0);
		opacity: 1;
		animation: slideIn 220ms ease-in-out forwards;
	}

	@keyframes slideIn {
		from {
			opacity: 0;
			transform: translateY(20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

    .generalModalActive {
     	transform: translateY(0);
		opacity: 1;
    }
    @media (max-width: 768px) {
        .modalItem  {
            width: 100%;
         
        }
    }
    `;
};
