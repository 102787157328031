export const userTimeZone = (alt?: boolean) => {
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const currentTime = new Date();
	const timeZoneOffsetMinutes = currentTime.getTimezoneOffset();
	const utcOffsetHours = -timeZoneOffsetMinutes / 60;
	const regionCity = typeof userTimeZone === "string" ? userTimeZone?.replace("_", " ") : "";
	const formattedUtcOffset =
		(utcOffsetHours >= 0 ? "+" : "-") + Math.abs(Math.floor(utcOffsetHours));

	if (alt) {
		return userTimeZone;
	}
	return `${regionCity}, UTC${formattedUtcOffset}`;
};
