import React, { useEffect, useRef, useCallback } from "react";
import Markdown from "react-markdown";
// import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";
import TypewriterEffect from "./TypewriterEffect";
import { useCitationModal } from "./usePopUpModals";
import { useCitationStore } from "../store/citationStore";
import { ModalType } from "./usePopUpModals";
import { CitationState } from "../store/citationStore";
import useBrand, { BrandStore } from "../store/brandStore";
import { getContrastColor } from "../utils/getContrastColor";
import { Paragraph, SnapSummary } from "../components/chatPopUp/StyledComponent";

// Define a discriminated union type for content parts
type ContentPart = { type: "text"; content: string } | { type: "imageGroup"; content: string[] };

const UseMarkdown = ({
	children,
	ClassName = "",
	streaming = false,
	Messages,
	index,
}: {
	children: string;
	ClassName?: string;
	streaming?: boolean;
	Messages: any;
	index: number;
}) => {
	const { brand } = useBrand((state: BrandStore) => state);
	const { onOpen } = useCitationModal((state: ModalType) => state);
	const { setCitation } = useCitationStore((state: CitationState) => state);

	const isSnapSummary = typeof children === "string" && children.startsWith("<snap_summary>");

	const processedChildren =
		typeof children === "string"
			? streaming
				? preprocessChildren(children.replace(/^###\s*/gm, ""))
				: preprocessChildren(children)
			: children;

	const hasCodeBlocks = /```/.test(children ?? "");
	const chartRef = useRef<HTMLDivElement | null>(null);
	const chartMatch = children.match(/<code_analyse>([\s\S]*?)<\/code_analyse>/);

	console.log(hasCodeBlocks, "code");

	const formatCodeBlock = useCallback((code: string) => {
		return code?.replace(/\\n/g, "\n")?.replace(/\\"/g, '" ')?.trim();
	}, []);

	const renderText = (text: any) => {
		if (typeof text !== "string" || !text) {
			return text;
		}

		const citationPattern = /\[{1,2}(\d+)\]{1,2}/g;
		const matches = text.match(citationPattern);

		if (matches && text === matches[0]) {
			const part = text.replace(/[[\]]/g, "");
			return (
				<button
					className=""
					onMouseEnter={(e) => {
						const timer = setTimeout(() => {
							const content = getCitationContent(part);
							if (content) {
								setCitation({
									source: content.source,
									url: content.url,
									title: content.title,
								});
								onOpen();
							}
						}, 500);
						(e.currentTarget as any)._hoverTimer = timer;
					}}
					onMouseLeave={(e) => {
						const timer = (e.currentTarget as any)._hoverTimer;
						if (timer) {
							clearTimeout(timer);
							(e.currentTarget as any)._hoverTimer = null;
						}
					}}
					style={{
						color: getContrastColor(`#${brand.secondaryColor}`),
						cursor: "pointer",
						padding: "5px",
						fontSize: "12px",
						backgroundColor: `#${brand.secondaryColor}`,
						borderRadius: "100%",
						width: "23px",
						height: "23px",
					}}
				>
					{part}
				</button>
			);
		}
		return text;
	};

	const getCitationContent = (citationKey: string) => {
		const message = Messages[index];
		if (!message || !message.responses) return null;

		for (const response of message.responses) {
			const citation = response.citations?.find((cit: any) => cit[citationKey]);
			if (citation) {
				return {
					source: citation[citationKey]?.source_id_content,
					url: citation[citationKey]?.url,
					title: citation[citationKey]?.title,
				};
			}
		}
		return null;
	};

	const decodeHtmlEntities = (str: string): string => {
		const textarea = document.createElement("textarea");
		textarea.innerHTML = str;
		return textarea.value;
	};

	const splitContentWithImages = (content: string): ContentPart[] => {
		const imageRegex = /<image\s+src="([^"]+?)"\s*\/>/g;
		const parts: ContentPart[] = [];
		const decodedContent = decodeHtmlEntities(content).replace(/\\n/g, "\n");
		const preprocessedContent = decodedContent.replace(/<em>|<\/em>/g, "_");

		let lastIndex = 0;
		let currentImageGroup: string[] = [];
		let match;

		while ((match = imageRegex.exec(preprocessedContent)) !== null) {
			const imageUrl = match[1].trim();
			const startIndex = match.index;
			const endIndex = imageRegex.lastIndex;

			// Add text before the image (if any)
			if (startIndex > lastIndex) {
				const textPart = preprocessedContent.slice(lastIndex, startIndex);
				const trimmedText = textPart.replace(/(<br\s*\/?>|\n)+/g, "").trim();
				if (trimmedText !== "") {
					if (currentImageGroup.length > 0) {
						parts.push({ type: "imageGroup", content: [...currentImageGroup] });
						currentImageGroup = [];
					}
					parts.push({ type: "text", content: textPart });
				}
			}

			// Validate and add the image to the current group
			try {
				new URL(imageUrl);
				currentImageGroup.push(imageUrl);
			} catch {
				// Skip invalid URLs
			}

			lastIndex = endIndex;
		}

		// Add remaining text after the last image (if any)
		if (lastIndex < preprocessedContent.length) {
			const textPart = preprocessedContent.slice(lastIndex);
			const trimmedText = textPart.replace(/(<br\s*\/?>|\n)+/g, "").trim();
			if (currentImageGroup.length > 0) {
				parts.push({ type: "imageGroup", content: [...currentImageGroup] });
				currentImageGroup = [];
			}
			if (trimmedText !== "") {
				parts.push({ type: "text", content: textPart });
			}
		}

		// If there are any remaining images in the group, add them
		if (currentImageGroup.length > 0) {
			parts.push({ type: "imageGroup", content: [...currentImageGroup] });
		}

		return parts;
	};

	const renderImagesAsStyledGrid = (images: string[]) => {
		if (images.length === 0) return null;

		const isOdd = images.length % 2 !== 0;
		return (
			<div className={`image-container-exe ${isOdd ? "odd-count-exo" : ""}`}>
				{images.map((url, index) => {
					const isLastAndOdd = isOdd && index === images.length - 1;
					const className = isLastAndOdd ? "full-width-exo" : "square-exo";
					return (
						<div key={index} className={`image-est90 ${className}`}>
							<img src={url} alt={`Img`} className="image-exe-tro" />
						</div>
					);
				})}
			</div>
		);
	};

	useEffect(() => {
		const renderChart = () => {
			if (chartMatch && chartRef.current) {
				try {
					chartRef.current.innerHTML = "";
					const scriptContent = formatCodeBlock(chartMatch[1]);
					const parser = new DOMParser();
					const doc = parser.parseFromString(scriptContent, "text/html");
					const canvas = doc.querySelector("canvas");
					const scripts = doc.querySelectorAll("script");
					if (canvas) chartRef.current.appendChild(canvas);
					scripts.forEach((script) => {
						if (script.textContent) {
							const executeScript = new Function(script.textContent);
							executeScript();
						}
					});
				} catch (error) {
					//
				}
			}
		};

		renderChart();
	}, [chartMatch, formatCodeBlock]);

	const contentParts = splitContentWithImages(processedChildren as string);

	if (contentParts.length > 0 && !hasCodeBlocks) {
		return (
			<div className={ClassName}>
				{contentParts.map((part, idx) => (
					<React.Fragment key={idx}>
						{part.type === "text" && part.content.trim() !== "" && (
							<TypewriterEffect
								renderText={renderText}
								text={part.content}
								speed={0}
								streaming={streaming}
							/>
						)}
						{part.type === "imageGroup" && renderImagesAsStyledGrid(part.content)}
					</React.Fragment>
				))}
				<div ref={chartRef}></div>
			</div>
		);
	}

	if (isSnapSummary) {
		return (
			<SnapSummary>
				<h3>Summary</h3>
				<TypewriterEffect
					renderText={renderText}
					text={(processedChildren as string).replace(/<snap_summary>|<br\s*\/?>/g, "")}
					speed={0}
					streaming={streaming}
				/>
			</SnapSummary>
		);
	}

	return (
		<div className={ClassName}>
			{hasCodeBlocks ? (
				<Markdown
					children={formatCodeBlock(children)}
					className={ClassName}
					components={{
						p: ({ children }) => <Paragraph>{renderText(children)}</Paragraph>,
						code({ node, ...props }) {
							const match = /language-(\w+)/.exec(props.className || "");
							return match ? (
								<SyntaxHighlighterWrapper {...props} language={match[1]}>
									{props.children}
								</SyntaxHighlighterWrapper>
							) : (
								<code
									{...props}
									className={props.className}
									style={{
										backgroundColor: "#f5f5f5",
										padding: "2px 4px",
										borderRadius: "4px",
										fontFamily: "monospace",
										fontSize: "13px",
										color: "#555",
										fontStyle: "italic",
										whiteSpace: "pre-wrap",
										wordBreak: "break-word",
									}}
								>
									{props.children}
								</code>
							);
						},
						...Array.from({ length: 6 }, (_, i) => {
							const Tag = `h${i + 1}` as keyof JSX.IntrinsicElements;
							return {
								[Tag]: ({ node, children, ...props }: any) => (
									<Tag
										{...props}
										style={{
											marginBlock: "10px",
											fontFamily: "CircularStd",
											color: "#000",
										}}
									>
										{children}
									</Tag>
								),
							};
						}).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
						ul: ({ node, ...props }) => (
							<ul
								{...props}
								style={{
									fontFamily: "CircularStd",
									marginTop: "10px",
									marginBottom: "10px",
									display: "flex",
									flexDirection: "column",
									gap: "1rem",
								}}
							/>
						),
						ol: ({ node, ...props }) => (
							<ol
								{...props}
								style={{
									fontFamily: "CircularStd",
									marginTop: "10px",
									marginBottom: "5px",
									display: "flex",
									flexDirection: "column",
									gap: "1rem",
								}}
							/>
						),
						li: ({ node, ...props }) => (
							<li
								{...props}
								style={{
									color: "#444",
									fontSize: "14px",
									fontWeight: "400",
								}}
							/>
						),
					}}
					remarkPlugins={[remarkGfm, remarkBreaks]}
				/>
			) : (
				<>
					<TypewriterEffect
						renderText={renderText}
						text={processedChildren as string}
						speed={0}
						streaming={streaming}
					/>
					<div ref={chartRef}></div>
				</>
			)}
		</div>
	);
};

export default UseMarkdown;
