import styled from "styled-components";
interface HeaderProps {
	isMessage?: boolean;
}
interface ActionsProps {
	streaming?: boolean;
	isLastMessage?: boolean;
}

const Main = styled.section`
	font-family: "FreightTextProMedium", sans-serif;
	height: 100%;
	z-index: 2147483647 !important;
`;

const ChatContent = styled.div`
	z-index: 1000000;
	pointer-events: all;
	position: absolute;
	bottom: 0rem;
	right: 0rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 100;
	width: 100vw;
	height: 90vh;
	border-radius: 10px 10px 0 0;
	padding: 10px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
	background-color: #fff;

	@media screen and (min-width: 768px) {
		width: 70vw;
		height: initial;
		right: 1rem;
		bottom: 1rem;
		top: 1rem;
		border-radius: 15px;
	}
	@media screen and (min-width: 1280px) {
		width:750px;
	}
`;

const Header = styled.section<HeaderProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px;
	border-radius: 10px 10px 0 0;
	background: ${(props) => (props.isMessage ? "#ffffff" : "transparent")};
	border-bottom: ${(props) => (props.isMessage ? "1px solid #efefef" : "none")};

	@media screen and (min-width: 1024px) {
		border-radius: 15px 15px 0 0;
	}
`;

const ActionsP = styled.div<ActionsProps>`
	opacity: ${(props) => (props.streaming ? 0 : props.isLastMessage ? 1 : 1)};
`;

const LogoContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	width: max-content;
`;

const Logo = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 4px;
`;

const Snapshot = styled.p`
	font-family: "CircularStd", sans-serif;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	color: #444;
	font-weight: 700;
`;

const PoweredByContainer = styled.div`
	font-family: "CircularStd", sans-serif;
`;

const PoweredBy = styled.p`
	text-align: center;
	color: #777;
	font-size: 12px;
	margin-top: -3px;
`;

const LinkPower = styled.a`
	color: #29645b;
	text-decoration: underline;
	text-decoration-color: #29645b;
`;

const Close = styled.div`
	cursor: pointer;
	width: 31px;
	height: 31px;
	padding: 10.7px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #fff;
`;

const ChatContainer = styled.section`
	font-family: "CircularStd", sans-serif;
	background-color: transparent;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	padding: 10px 16px 20px;
	display: flex;
	flex-direction: column;
	display: flex;
	flex-direction: column;
	align-content: flex-end;
	background: #fff !important;
`;

const PromptBoxChat = styled.section`
	background: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 0px 9px;

	@media screen and (min-width: 1024px) {
		border-radius: 0 0 15px 15px;
	}
`;

const Chats = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

const PromptGroup = styled.div`
	animation: typing 500ms ease-in-out forwards;
	max-width: 100%;
	margin-top: 50px;
	margin-bottom: 30px;

	@keyframes typing {
		from {
			opacity: 0;
			transform: translateY(10px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const PromptWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
`;

const Prompt = styled.p`
	font-family: "CircularStd", sans-serif;
	white-space: pre-wrap;
	align-self: flex-end;
	word-break: break-word;
	background: transparent;
	color: #444;
	font-size: 15.2px;
	font-weight: 400;
	border-radius: 6px;
	background-color: #f5f7f6;
	padding: 8px;
`;

const ResponseContainer = styled.div`
	display: flex;
	gap: 17px;
	height: 100%;
	width: 100%;
	width: 100%;
	align-self: flex-start;
	border-radius: 0px 15px 15px 15px;
	font-weight: 500;
	justify-content: center;
	animation: fadeIn 1s ease-in-out forwards;

	/* &:hover {
		.copy-xs {
			opacity: 0;
			visibility: visible;
		}
	} */

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const TriggerImg = styled.img`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: none;
`;

const Response = styled.div`
	font-family: "CircularStd", sans-serif;
	position: relative;
	width: 100%;
	align-self: flex-start;
	border-radius: 0px 15px 15px 15px;
	color: #444;
	font-weight: 400;
	justify-content: center;
	font-size: 15.2px;
	animation: fadeIn 1s ease-in-out forwards;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const CopyResponse = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
`;

const FeedbackContainer = styled.div`
	display: grid;
	grid-template-columns: 28px auto;
	align-items: flex-start;
	gap: 10px;
`;

const LoadingPulse = styled.img`
	width: 28px;
	height: 28px;
	border-radius: 50%;
	animation: pulse 1.2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

	@keyframes pulse {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0.3;
		}
	}
`;

const Feedback = styled.p`
	font-family: "CircularStd", sans-serif;
	font-size: 15px;
	color: #4444;
	font-style: italic;
	margin-top: 2.3px;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	-webkit-background-clip: text;
	background-clip: text;
	mask-image: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 50%,
		rgba(0, 0, 0, 0) 100%
	);
	mask-size: 200% 100%;
	animation: shimmer 1.8s infinite;

	@keyframes shimmer {
		0% {
			mask-position: 100%;
		}
		100% {
			mask-position: -100%;
		}
	}
`;

const Paragraph = styled.p`
	font-family: "CircularStd", sans-serif;
	align-self: flex-start;
	color: #444;
	font-size: 15.2px;
	font-weight: 400;
	width: 100%;
	word-break: break-word;
	width: 100%;

	/* @keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
} */
`;

const SnapSummary = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #fafafa;
	font-size: 14px;
	font-weight: 400;
	padding: 10px;
	height: 100%;
	height: max-content;
	padding-inline: 20px;
	padding-block: 10px;
	border: 1px solid #e0e0e097;
	border-radius: 10px;
	font-style: italic;
	color: #444444;
`;

export {
	Main,
	ChatContent,
	Header,
	ChatContainer,
	LogoContainer,
	Logo,
	Snapshot,
	PoweredByContainer,
	PoweredBy,
	LinkPower,
	Close,
	ActionsP,
	FeedbackContainer,
	LoadingPulse,
	Feedback,
	CopyResponse,
	ResponseContainer,
	Response,
	PromptGroup,
	PromptWrapper,
	Prompt,
	TriggerImg,
	Chats,
	Paragraph,
	PromptBoxChat,
	SnapSummary,
};
