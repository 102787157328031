export const chatInterfaceStyle = () => {
	return `
	.main {
		z-index: 1000000;
	}

	.trigger {
		cursor: pointer;
		background-color: transparent;
		width: 38px;
		height: 38px;
		border-radius: 50%;
	}

	.trigger:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
		transition: box-shadow 0.3s ease;
	}

	.sm {
		width: 30px;
		height: 30px;
	}

	.md {
		width: 38px;
		height: 38px;
	}

	.lg {
		width: 58px;
		height: 58px;
	}

	.triggerImg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	@media screen and (min-width: 768px) {
		.trigger {
			width: 48px;
			height: 48px;
		}

		.sm {
			width: 36px;
			height: 36px;
		}

		.md {
			width: 48px;
			height: 48px;
		}

		.lg {
			width: 68px;
			height: 68px;
		}
	}
	`;
};
